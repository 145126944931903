var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "appDownload"
    },
    _vm._l(_vm.apps, function(item) {
      return _c(
        "div",
        { key: item.name },
        [
          _c("img", { attrs: { src: item.ico, alt: "" } }),
          _c("b", [_vm._v(_vm._s(item.name))]),
          _c(
            "el-button",
            {
              attrs: {
                size: "small",
                type: "maiaPrimary",
                title: item.url ? "" : "正在获取下载地址",
                disabled: !item.url
              },
              on: {
                click: function($event) {
                  return _vm.appDownload(item.url)
                }
              }
            },
            [_vm._v("下载")]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }