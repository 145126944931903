import request from '@/utils/request';
export default {
  getDicDatas: function getDicDatas() {
    //获取全部字典项分类 
    return request.get('/app/v1/dic/list-dicInfo').then(function (res) {
      return res;
    });
  },
  addDicDatas: function addDicDatas(params) {
    //添加字典项分类 
    return request.post('/app/v1/dic/add-dicInfo', params).then(function (res) {
      return res;
    });
  },
  getDicMessage: function getDicMessage(dicCode) {
    //获取字典项分类详情
    return request.get('/app/v1/dic/get-dicInfo?dicCode=' + dicCode).then(function (res) {
      return res;
    });
  },
  updateDicInfo: function updateDicInfo(params) {
    //修改字典项分类  修改字典项分类数据
    return request.post('/app/v1/dic/update-dicInfo', params).then(function (res) {
      return res;
    });
  },
  updateDicContent: function updateDicContent(params) {
    // 修改字典项分类数据
    return request.post('/app/v1/dic/update-dicContent', params).then(function (res) {
      return res;
    });
  },
  deleteDicInfo: function deleteDicInfo(dicCode) {
    //删除字典项分类
    return request.get('/app/v1/dic/delete-dicInfo?dicCode=' + dicCode).then(function (res) {
      return res;
    });
  },
  pageDicContent: function pageDicContent(dicCode, pageNo, pageSize) {
    //分页获取字典项分类数据
    return request.get('/app/v1/dic/page-dicContent?dicCode=' + dicCode + '&pageNo=' + pageNo + '&pageSize=' + pageSize).then(function (res) {
      return res;
    });
  },
  addDicContent: function addDicContent(params) {
    //添加字典项分类数据
    return request.post('/app/v1/dic/add-dicContent', params).then(function (res) {
      return res;
    });
  },
  getDicContent: function getDicContent(id) {
    //查询字典项分类数据详情
    return request.get('/app/v1/dic/get-dicContent?id=' + id).then(function (res) {
      return res;
    });
  },
  deleteDicContent: function deleteDicContent(dicCode, id) {
    //删除字典项分类数据
    return request.get("/app/v1/dic/delete-dicContent/".concat(dicCode, "?id=") + id).then(function (res) {
      return res;
    });
  },
  batchdelDicContent: function batchdelDicContent(dicCode, ids) {
    //批量删除字典项分类数据
    return request.post("/app/v1/dic/batchdel-dicContent/".concat(dicCode), ids).then(function (res) {
      return res;
    });
  },
  moveDicContent: function moveDicContent(dicCode, moveFlag, id) {
    //字典项：1 上移 ，2 下移
    return request.get("/app/v1/dic/move-dicContent/".concat(dicCode, "?moveFlag=") + moveFlag + '&id=' + id).then(function (res) {
      return res;
    });
  },
  showLogPagelist: function showLogPagelist(typeId, startTime, endTime, pageNo, pageSize, search) {
    //操作日志接口少，先写这
    return request.get('/app/v1/operlog/page-list?typeId=' + typeId + '&startTime=' + startTime + '&endTime=' + endTime + '&pageNo=' + pageNo + '&pageSize=' + pageSize + '&search=' + search).then(function (res) {
      return res;
    });
  }
};